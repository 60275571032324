import React from 'react';
import Error500 from '../components/Error/500';
import Layout from '../layouts/Layout';

const Page500 = () => {
  return (
    <Layout>
      <Error500 className="my-12" />
    </Layout>
  );
};

export default Page500;
